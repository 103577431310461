import axios from "axios";
import { emitToastr } from "./Utils";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3005/admin" : "https://painel.inteligenciabot.com/admin";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async () => {
    const user = await this.authPost("/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }

    return false;
  };

  uploadProfilePicture = async (image) => {
    const resp = await this.authPost("/upload-profile-picture", { image: image }, { multipart: true, formData: true }); //ESSE TEM ESSAS OPÇÕES PORQUE ESTÁ ENVIANDO UMA IMAGEM AO INVÉS DE UM JSON
    return resp;
  };

  uploadImage = async (image) => {
    const resp = await this.authPost("/upload-image", { image: image }, { multipart: true, formData: true });
    return resp;
  };

  uploadFile = async (file, type, filename, temp = false) => {
    const resp = await this.authPost("/upload-file", { file, type, filename, temp }, { multipart: true, formData: true });
    return resp;
  };

  createUser = async (email, password, name, phone) => {
    const resp = await this.authPost("/create-user", { email, password, name, phone }, {});

    if (resp && resp.new_user && resp.token) {
      window.localStorage.setItem("token", resp.token);
      window.localStorage.setItem("user", JSON.stringify(resp.new_user));
      return true;
    }
    return false;
  }

  updateUser = async (user) => {
    const resp = await this.authPost("/update-user", { user }, {});
    return resp;
  }

  createTeamUser = async (object) => {
    const resp = await this.authPost("/create-team-user", object, {});
    return resp;
  }

  getTeamUsers = async () => {
    const resp = await this.authPost("/get-team-users", {}, {});
    return resp;
  }

  getUsers = async () => {
    const resp = await this.authPost("/get-users", {}, {});
    return resp;
  }

  getSalesByUserAndMode = async () => {
    return {}
  }

  createOrUpdateChipNumber = async (object) => {
    const resp = await this.authPost("/create-or-update-chip-number", object, {});
    return resp;
  }

  getChipNumbersByUser = async () => {
    const resp = await this.authPost("/get-chip-numbers-by-user", {}, {});
    return resp;
  }

  connectChipNumber = async (chip, connect = true) => {
    const resp = await this.authPost("/connect-chip-number", { chip, connect }, {});
    return resp;
  }

  changeChipNumberChatbot = async (chip) => {
    const resp = await this.authPost("/change-chip-number-chatbot", { chip }, {});
    return resp;
  }

  renameChipNumber = async (chip) => {
    const resp = await this.authPost("/rename-chip-number", { chip }, {});
    return resp;
  }

  recoveryPassword = async (email) => {
    const resp = await this.authPost("/send-password-recovery", { email: email }, {});
    return resp;
  };

  changeUserPassword = async (password, old_password) => {
    const resp = await this.authPost("/change-user-password", { password, old_password }, {});
    return resp;
  };

  getDashboardData = async (interval, selected_chip) => {
    const resp = await this.authPost("/get-dashboard-data", { interval, selected_chip }, {});
    return resp;
  };

  getDashboardRealTimeData = async (selected_chip) => {
    const resp = await this.authPost("/get-dashboard-real-time-data", { selected_chip }, {});
    return resp;
  };

  getAdminDashboardData = async (interval) => {
    const resp = await this.authPost("/get-admin-dashboard-data", { interval }, {});
    return resp;
  };

  getFinancialDashboardData = async () => {
    const resp = await this.authPost("/get-financial-dashboard-data", { }, {});
    return resp;
  };

  createOrUpdateChatbot = async (object) => {
    const resp = await this.authPost("/create-or-update-chatbot", object, {});
    return resp;
  }

  getChatbotsByUser = async () => {
    const resp = await this.authPost("/get-chatbots-by-user", {}, {});
    return resp;
  }

  getFlowsByUser = async () => {
    const resp = await this.authPost("/get-flows-by-user", {}, {});
    return resp;
  }

  getFlowById = async (flow_id) => {
    const resp = await this.authPost("/get-flow-by-id", { flow_id }, {});
    return resp;
  }

  createOrUpdateFlow = async (object) => {
    const resp = await this.authPost("/create-or-update-flow", object, {});
    return resp;
  }

  duplicateFlow = async (flow_id, name) => {
    const resp = await this.authPost("/duplicate-flow", { flow_id, name }, {});
    return resp;
  }

  getContactsByUser = async (options) => {
    const resp = await this.authPost("/get-contacts-by-user", options, {});
    return resp;
  }

  getContactById = async (contact_id) => {
    const resp = await this.authPost("/get-contact-by-id", { contact_id }, {});
    return resp;
  }

  ignoreContact = async (contact) => {
    const resp = await this.authPost("/ignore-contact", { contact }, {});
    return resp;
  }

  changeContactTags = async (contact) => {
    const resp = await this.authPost("/change-contact-tags", { contact }, {});
    return resp;
  }

  changeContactName = async (contact) => {
    const resp = await this.authPost("/change-contact-name", { contact }, {});
    return resp;
  }

  changeContactAnnotations = async (contact) => {
    const resp = await this.authPost("/change-contact-annotations", { contact }, {});
    return resp;
  }

  getConversationsByUser = async (options) => {
    const resp = await this.authPost("/get-conversations-by-user", options, {});
    return resp;
  }

  getConversationsWithLastMessage = async (options) => {
    const resp = await this.authPost("/get-conversations-with-last-message", options, {});
    return resp;
  }

  getConversationsUpdate = async (conversation_id) => {
    const resp = await this.authPost("/get-conversation-update", { conversation_id }, {});
    return resp;
  }

  getMessagesByConversation = async (conversation_id) => {
    const resp = await this.authPost("/get-messages-by-conversation", { conversation_id }, {});
    return resp;
  }

  finishConversation = async (conversation_id) => {
    const resp = await this.authPost("/finish-conversation", { conversation_id }, {});
    return resp;
  }

  resumeConversation = async (conversation_id) => {
    const resp = await this.authPost("/resume-conversation", { conversation_id }, {});
    return resp;
  }

  setConversationManual = async (conversation_id) => {
    const resp = await this.authPost("/set-conversation-manual", { conversation_id }, {});
    return resp;
  }

  changeConversationData = async (conversation) => {
    const resp = await this.authPost("/change-conversation-data", conversation, {});
    return resp;
  }

  sendConversationMessage = async (conversation_id, text, message_id, media) => {
    const resp = await this.authPost("/send-conversation-message", { conversation_id, text, message_id, media }, {});
    return resp;
  }

  deleteConversationMessage = async (platform_message_id, phone, chip_number_id) => {
    const resp = await this.authPost("/delete-conversation-message", { platform_message_id, phone, chip_number_id }, {});
    return resp;
  }

  getQuickMessagesByUser = async () => {
    const resp = await this.authPost("/get-quick-messages-by-user", {}, {});
    return resp;
  }

  createOrUpdateQuickMessage = async (object) => {
    const resp = await this.authPost("/create-or-update-quick-message", object, {});
    return resp;
  }

  getOpenManualConversationCount = async () => {
    const resp = await this.authPost("/get-open-manual-conversation-count", {}, {});
    return resp;
  }

  getGuruSubscription = async () => {
    const resp = await this.authPost("/get-guru-subscription", {}, {});
    return resp;
  }

  getTagsByUser = async () => {
    const resp = await this.authPost("/get-tags-by-user", {}, {});
    return resp;
  }

  createOrUpdateTag = async (object) => {
    const resp = await this.authPost("/create-or-update-tag", object, {});
    return resp;
  }

  getDepartmentsByUser = async () => {
    const resp = await this.authPost("/get-departments-by-user", {}, {});
    return resp;
  }

  createOrUpdateDepartment = async (object) => {
    const resp = await this.authPost("/create-or-update-department", object, {});
    return resp;
  }

  checkPhone = async (phone) => {
    const resp = await this.authPost("/check-phone", { phone }, {});
    return resp;
  }

  startNewConversation = async (jid, chip) => {
    const resp = await this.authPost("/start-new-conversation", { jid, chip }, {});
    return resp;
  }

  getMediasByUser = async () => {
    const resp = await this.authPost("/get-medias-by-user", {}, {});
    return resp;
  }

  deleteMedia = async (media) => {
    const resp = await this.authPost("/delete-media", media, {});
    return resp;
  }

  getScheduledMessagesByUser = async () => {
    const resp = await this.authPost("/get-scheduled-messages-by-user", {}, {});
    return resp;
  };

  createScheduledMessage = async (object) => {
    const resp = await this.authPost("/create-scheduled-message", object, {});
    return resp;
  };

  updateScheduledMessage = async (object) => {
    const resp = await this.authPost("/update-scheduled-message", object, {});
    return resp;
  };

  getAudienceByUser = async () => {
    const resp = await this.authPost("/get-audience-by-user", {}, {});
    return resp;
  };

  createOrUpdateAudience = async (object) => {
    const resp = await this.authPost("/create-or-update-audience", object, {});
    return resp;
  };

  sendAudienceList = async (data, file, save = false) => {
    const endpoint = '/import-audience-sheet';
    const result = await this.authPost(endpoint, { ...data, file: file, save: save }, { multipart: true, formData: true });
    return result;
  }

  getCampaignsByUser = async () => {
    const resp = await this.authPost("/get-campaigns-by-user", {}, {});
    return resp;
  };

  createCampaign = async (object) => {
    const resp = await this.authPost("/create-campaign", object, {});
    return resp;
  };

  updateCampaign = async (object) => {
    const resp = await this.authPost("/update-campaign", object, {});
    return resp;
  };

  getCampaignRealTimeData = async (object) => {
    const resp = await this.authPost("/get-campaign-real-time-data", object, {});
    return resp;
  };

  updateUserApiKey = async () => {
    const resp = await this.authPost("/update-api-key", {}, {});
    return resp;
  }

  exportContacts = async () => {
    const endpoint = '/export-contacts';
    const result = await this.authPost(endpoint, {}, { arraybuffer: true });
    return result;
  }

  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) {
      emitToastr("error", data.message || "Erro não identificado!", "Erro");
    }

    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers, responseType: options && options.arraybuffer ? 'arraybuffer' : undefined })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        console.error(err.response);
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return { error: true, message: err.response.data.message };
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
